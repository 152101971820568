import React from 'react'

export default function Video() {
    return (
        <div className='w-full h-auto py-2'>
            <video  loop muted   autoPlay  className='m-auto'>  
                <source src="video1.mp4"  autoPlay type="video/mp4"/>
                    Your browser does not support the video tag.
            </video>
        </div>
    )
}
