import React from 'react'

export default function LockingProprity() {

    const listing = [
        {
            img: "looking/1.jpg",
            h:"Apartment for rent"
        },
        {
            img: "looking/2.jpg",
            h:"Family Home"
        },
        {
            img: "looking/3.jpg",
            h:"Resort Villas"
        },
        {
            img: "looking/4.jpg",
            h:"Office Building"
        }
    ]
    return (
        <div className='w-full'>
            <div className='md:w-[80%] m-auto p-4'>
                <h1 className='text-[28px] py-4 text-center'>LOOKING PROPERTY</h1>
                <p className='pb-5 text-center'>What kind of property are you looking for? We will help you</p>


                <div className='w-full grid md:grid-cols-4 gap-4 sm:grid grid-cols-1 py-5'>
                    {
                        listing.map(
                            (d, i) => {
                                return (
                                    <div key={i} className=''>
                                        <img src={d.img} alt=" " className='w-full min:h-[250px] pb-4' />
                                        <h1 className='w-full  text-[20px] text-center '>{d.h}</h1>
                                    </div>
                                )
                            }
                        )
                    }


                </div>
            </div>
        </div>
    )
}
