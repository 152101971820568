import React from 'react'
import { FaArrowRight } from "react-icons/fa";
import { MdOutlineBusiness ,MdAddBusiness,MdOutlineSupervisorAccount  } from "react-icons/md";
import { TbBusinessplan } from "react-icons/tb";





export default function Desigin() {
    const dision = [
        {
            img: <MdOutlineSupervisorAccount />
            ,
            name: "Trusted by Thousands",
            p:"A small river named Duden flows by their place and supplies it with the necessary regelialia."
        },
        

        {
            img: <MdOutlineBusiness />
            ,
            name: "Wide Range of Properties",
            p:"A small river named Duden flows by their place and supplies it with the necessary regelialia."
        },
        

        {
            img: <MdAddBusiness  />
            ,
            name: "Financing Made Easy",
            p:"A small river named Duden flows by their place and supplies it with the necessary regelialia."
        },
        

        {
            img: <TbBusinessplan />
            ,
            name: "Locked in Pricing",
            p:"A small river named Duden flows by their place and supplies it with the necessary regelialia."
        },
        

        
    ]
    return (
        <div className='w-full pt-5 '>


            <div  className='w-full p-4'>
                <div className='w-[85%] m-auto gap-3 py-4  grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 '>
                    {
                        dision.map(
                            (d, i) => {
                         
                                return (

                                    <div key={i} className='bg-[#5d5dba] hover:bg-[#0a0a0a] group py-3 duration-1000 rounded-xl text-white px-4 w-full text-center'>
                                        <div className='w-full flex justify-center items-center h-[100px]'>
                                            <h1  className='group-hover:pt-2  text-[82px] duration-500 text-[#1f1e2d] group-hover:text-white' >{d.img}</h1>
                                           
                                        </div>
                                        <h1 className='text-[white] py-2 text-center  text-[20px]'>{d.name}</h1>
                                        <p className='text-gray-300 text-[14px]'>{d.p}</p>
                                        <h1 className='text-center flex justify-center p-4 w-full'>  <FaArrowRight /></h1>

                                    </div>

                                )
                            }
                        )
                    }
                </div>
            </div>
        </div>
    )
}
