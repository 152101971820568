import React from 'react'
import { MdAddCall } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { FaLocationCrosshairs } from "react-icons/fa6";

export default function Contact() {
    return (
        <div>
            <div className='max-w-[1000px] my-3 m-auto grid md:grid-cols-3 grid-cols-1 ' style={{ boxShadow: " rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px" }}>
                <div className='p-5 mt-8 w-full sm:px-[50px] md:px-[20px]  '>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7121.837915073944!2d75.806104!3d26.810709!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396dcb95d60d5bc1%3A0x656854a5c65d4245!2sMukundrajputiparidhan!5e0!3m2!1sen!2sin!4v1710225508376!5m2!1sen!2sin" className='w-full  md:h-[500px] h-[500px]'></iframe>

                </div>
                <div className='bg-white col-span-2 grid mt-8 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 pl-2'>
                    <div className='flex my-8 md:my-20'>
                        <div className='w-[100px] h-[100px] flex justify-center items-center text-blue-500 text-[32px] '><MdAddCall /></div>
                        <div>
                            <h2 className='p-2 font-bold'>Contact Us</h2>
                            <p className='pl-2'>Digiskymanager@gmail.com</p>
                            <p className='pl-2'>+91 99292 45508</p>
                        </div>
                    </div>
                    <div className='flex  my-8 md:my-20'>
                        <div className='w-[100px] h-[100px] flex justify-center items-center text-blue-500 text-[32px] '><FaWhatsapp className='animate-ping' /></div>
                        <div>
                            <h2 className='p-2 font-bold hover:text-blue-500 cursor-pointer '>ChatNow</h2>
                            <p className='pl-2'>+91 99292 45508</p>

                        </div>
                    </div>
                    <div >
                        <div className='flex  my-8 md:my-20'>
                            <div className='w-[200px] h-[100px] flex justify-center items-center text-blue-500 text-[32px] '><FaLocationCrosshairs /></div>
                            <div>
                                <h2 className='p-2 font-bold'>Address</h2>
                                <p className='pl-2'>23, 1st Floor, Gajananad Market, Sheopur Road, Pratap Nagar, Jaipur , (Raj.) 302033</p>

                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </div>
    )
}
