import React from 'react'
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import Main from './pages/Main';
import HomePage from './pages/HomePage';
import Contact from './components/Contact';

const routers = createBrowserRouter(
  [
    {
      path: "/",
      element: <Main />,
      children:[
        {
          path:"/",
          element:<HomePage/>
        },{
          path:"/contact",
          element:<Contact/>
        }
      ]
    }
  ]
)

export default function App() {

  return (
    <>
      <RouterProvider router={routers} />
    </>
  )
}
