import React from 'react';
import CountUp from 'react-countup';

export default function Numbring() {

    const number=[
        {
            num: 2917, // Remove commas and convert to a numeric value
            p: "# of Buy Properties"
        },
        {
            num: 3918, // Remove commas and convert to a numeric value
            p: "# of Sell Properties"
        },
        {
            num: 38928, // Remove commas and convert to a numeric value
            p: "# of All Properties"
        },
        {
            num: 1291, // Remove commas and convert to a numeric value
            p: "# of Agents"
        },
    ];

    return (
        <div className='w-[80%] m-auto grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1'>
           {
            number.map(
                (d,i)=>{
                    return(
                        <div className='w-full ' key={i}>
                            <h1 className='text-[25px] text-center font-bold'><CountUp end={d.num} /></h1>
                            <h1 className='text-center'>{d.p}</h1>
                        </div>
                    )
                }
            )
           }
        </div>
    )
}
