import {React,  useContext } from 'react'
import { MainContext } from '../context/Context';

import { FaArrowUp, FaFacebook, FaWhatsappSquare, FaInstagramSquare, FaYoutube, FaLinkedin } from "react-icons/fa";

export default function Footer() {
  const {scrollToTop}= useContext(MainContext)
  
  return (
    <footer className='relative setbg pt-8 pb-6 text-white'>
      <div className='container mx-auto px-4 '>
        <div className='w-full  grid md:grid-cols-3 grid-cols-1 gap-12 md:px-12 pt-12'>
          <div>
            <h3 className='text-4xl'>Let's keep in touch!</h3>
            <h5 className='text-lg'>Find us on any of these platforms, we respond 1-2 business days.</h5>
            <div className='py-4 flex gap-3 text-[32px] '>
              <span className='cursor-pointer rounded-full text-black bg-[#D4D4D4] '><FaFacebook className='rounded-full' /></span>

              <span className='rounded-full cursor-pointer text-black bg-[#D4D4D4]  '><FaWhatsappSquare className='rounded-full' /></span>

              <span className='rounded-full cursor-pointer text-black bg-[#D4D4D4] ' ><FaInstagramSquare className='rounded-full' /></span>

              <span className='rounded-full cursor-pointer text-black bg-[#D4D4D4] ' ><FaYoutube className='rounded-full' /></span>
              <span className='rounded-full cursor-pointer text-black bg-[#D4D4D4] ' ><FaLinkedin className='rounded-full' /></span>

            </div>
          </div>
          <div className='md:pl-16'>
            <span className='block text-[20px ] font-bold pb-2'>USEFUL LINKS</span>
            <ul className="list-unstyled text-[12px]">
              <li><a className=' block text-[15px] pb-2 hover:text-[#5858c2]' href="">Flat</a></li>
              <li><a className=' block text-[15px] pb-2 hover:text-[#5858c2]' href="">Flot</a></li>
              <li><a className='block text-[15px] pb-2 hover:text-[#5858c2]' href="">Vila </a> </li>
              <li><a className=' block text-[15px] pb-2 hover:text-[#5858c2]' href="">Contact</a></li>
            </ul>
          </div>
          <div>
            <h2 className='font-bold'>REGISTATION OFFICE</h2>
            <ul>
              <li className='mt-2'>23, 1st Floor, Gajananad Market, Sheopur Road, Pratap Nagar, Jaipur (Raj.) 302033</li>
              <li className='mt-2'>Digiskymanager@gmail.com</li>
              <li className='mt-2'>+91-9929245508</li>
            </ul>
          </div>
        </div>
        <div className='w-full  flex justify-end'>
          <button onClick={scrollToTop}  className="animate-bounce inline-block  text-[#0400ff] border-2 border-[#0400ff] rounded-full p-3 box-border" ><FaArrowUp /></button>
        </div>
        <hr className='w-full my-2 border-gray-500' />
        <div className='w-full flex justify-center items-center font-bold'>All Right Reserved. Copyright © Trading</div>
      </div>
    </footer>
  )
}


//       <div className='w-full flex justify-between px-10 mx-auto md:max-w-[1400px] text-xl py-2  text-center h-[70px] items-center text-white'>
//     <div>  Blog-<span className='text-[red]'>st</span>  </div>
//     <div>Stay Connected with Blogst</div>
//         <FaRegArrowAltCircleUp onClick={scrollToTop} className='text-3xl cursor-pointer animate-bounce'/>

//       </div>
//     </div>
//   )
// }
