import React from 'react'

export default function ProprityType() {

    const dision = [
        {
            img: "proprtytype/property1.svg"
            ,
            name: "Home & Appartment ",
          
        },
        {
            img: "proprtytype/property2.svg"
            ,
            name: "Vila  ",
          
        },

        {
            img: "proprtytype/property3.svg"
            ,
            name: "Studio ",
          
        },

        {
            img: "proprtytype/property4.svg"
            ,
            name: "Office ",
          
        },





    ]
    return (
        <div className='w-full py-9 '>


            <div className='w-full p-4'>

                <h1 className='text-[32px] text-center  font-semibold underline py-2'>Explore by Property Type</h1>
                <p className='text-[22px] sm:w-[50%] m-auto text-center text-gray-400 py-2'>Get started by choosing from one of our pre-built page templates to showcase your properties</p>
                <div className='w-[85%] m-auto gap-3 py-4  grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 '>
                    {
                        dision.map(
                            (d, i) => {

                                return (

                                    <div key={i} className='bg-[#5d5dba] hover:bg-[#3c3c48] group py-3 group duration-1000 rounded-xl text-white px-4 w-full text-center'>
                                        <div className='w-full flex justify-center items-center h-[100px]'>
                                            <img src={d.img} alt="" className='group-hover:pt-2   text-[82px] duration-500' />
                                        </div>
                                        <h1 className='text-[#ffffff] group-hover:text-white py-2 text-center  text-[20px]'>{d.name}</h1>

                                    </div>

                                )
                            }
                        )
                    }
                </div>
            </div>
        </div>
    )
}
