import React from 'react'

export default function HelpPeople() {
    const help = [
        {
            img: "help/1.svg",
            name: "Sell home or office",
            p:"Get started by choosing from one of our pre-built page templates to showcase your properties"
        },
        {
            img: "help/2.svg",
            name: "Rent home or office",
            p:"Get started by choosing from one of our pre-built page templates to showcase your properties"

        },
        {
            img: "help/3.svg",
            name: "Find next",
            p:"Get started by choosing from one of our pre-built page templates to showcase your properties"

        },
    ]
    return (
        <div className='w-full min:h-[600px] ' style={{ backgroundImage: "URL(crousel/11.jpg)", backgroundSize: "100% 100%" }}>
            <div className='w-full bg-[#000000a8] h-full'>
                <h1 className='pt-10 text-[38px] text-center  text-white'>How we help people?</h1>

                <div className='sm:w-[70%] m-auto pt-4 pb-12  grid sm:grid-cols-3 sm:gap-2 gap-4  grid-cols-1 p-2' >

                    {
                        help.map(
                            (d, i) => {
                                return (
                                    <div key={i} className='bg-[#1f1e1e] hover:bg-[#3c3c48] group py-3 duration-1000 rounded-xl text-white px-4 w-full text-center'>
                                        <div className='w-full flex justify-center items-center py-2 h-[100px]'>
                                            <img src={d.img} alt="" className='group-hover:pt-2  text-[82px] duration-500' />
                                        </div>
                                        <h1 className='text-[white] p-1 text-center font-semibold  text-[20px]'>{d.name}</h1>
                                        <p className='text-gray-300 text-[15px] p-2'>{d.p}</p>

                                    </div>
                                )
                            }
                        )
                    }

                </div>

            </div>
        </div>
    )
}
