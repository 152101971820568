
import React, { createContext,useEffect,useState } from 'react'
const MainContext=createContext();
export default function Context(props) {
  //scroll bottom to top function 
  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth', // Adds a smooth scrolling animation
    });
  }

  return (

    <MainContext.Provider value={{scrollToTop}}>
    {props.children}

    </MainContext.Provider>
  )
}


export {MainContext}