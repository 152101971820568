import React from 'react'
import { Carousel } from "flowbite-react";

export default function Crousel() {
    return (
    <div className="lg:h-[80vh] md:h-[65vh] h-[45vh]" >
            <Carousel>
                <img src="crousel/11.jpg"  className='w-full h-full' alt="..." />
                <img src="crousel/15.jpg"  className='w-full h-full' alt="..." />
                <img src="crousel/14.jpg" className='w-full h-full' alt="..." />
                <img src="crousel/7.jpg" className='w-full h-full' alt="..." />
                <img src="crousel/6.jpg" className='w-full h-full' alt="..." />

  
            </Carousel>
        </div>
    )
}
